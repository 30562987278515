import React, { useRef, useEffect, useState } from 'react';
import { Card } from '../ui/card';
import { HelpCircle, Loader2 } from 'lucide-react';
import ClarifyingQuestion from '../ClarifyingQuestion';
import LoadingPlaceholder from '../LoadingPlaceholder';
import { useToast } from '../../contexts/ToastContext';
import { submitQuestionAnswer } from '../../services/api';

const QuestionsPanel = ({ 
  isLoading, 
  questions, 
  isAnalysisComplete, 
  isAnalyzing,
  onQuestionsUpdate,
  onTicketsUpdate, 
  onExpertResponse 
}) => {
  const scrollRef = useRef(null);
  const { addToast } = useToast();
  const [submittingQuestionId, setSubmittingQuestionId] = useState(null);
  const [processedQuestions, setProcessedQuestions] = useState([]);

  // Process and deduplicate questions when they update
  useEffect(() => {
    if (Array.isArray(questions)) {
      const uniqueQuestions = questions.reduce((acc, question) => {
        // Skip if we already have this question
        if (acc.some(q => q.id === question.id)) {
          return acc;
        }
        
        // Ensure question has all required fields
        const processedQuestion = {
          id: question.id,
          question_text: question.question_text || question.text || question,
          answer_text: question.answer_text || null,
          is_answered: question.is_answered || false,
          affected_tickets: question.affected_tickets || [],
          expert_id: question.expert_id
        };
        
        return [...acc, processedQuestion];
      }, []);

      setProcessedQuestions(uniqueQuestions);
    }
  }, [questions]);

  // Scroll to bottom when new questions arrive
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [processedQuestions]);

  const handleAnswer = async (questionId, answer) => {
    setSubmittingQuestionId(questionId);
    try {
      const response = await submitQuestionAnswer(questionId, answer);
      console.log('Answer response:', response);

      if (response.success) {
        const updatedQuestions = processedQuestions.map(q => 
          q.id === questionId ? {
            ...q,
            answer_text: answer,
            is_answered: true
          } : q
        );

        onQuestionsUpdate(updatedQuestions);

        if (response.updated_tickets || response.new_tickets) {
          onTicketsUpdate(response.updated_tickets, response.new_tickets);
        }

        if (response.expert_response) {
          onExpertResponse(response.expert_response);
        }
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
      addToast('Failed to submit answer. Please try again.', 'error');
    } finally {
      setSubmittingQuestionId(null);
    }
  };

  const handleDismiss = async (questionId) => {
    setSubmittingQuestionId(questionId);
    try {
      const response = await submitQuestionAnswer(questionId, null, true);

      if (response.success) {
        const updatedQuestions = processedQuestions.map(q => 
          q.id === questionId ? {
            ...q,
            answer_text: 'Question dismissed',
            is_answered: true
          } : q
        );
        onQuestionsUpdate(updatedQuestions);
      }
    } catch (error) {
      console.error('Error dismissing question:', error);
      addToast('Failed to dismiss question. Please try again.', 'error');
    } finally {
      setSubmittingQuestionId(null);
    }
  };

  const pendingQuestions = processedQuestions.filter(q => !q.is_answered);
  const answeredQuestions = processedQuestions.filter(q => q.is_answered);

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPlaceholder />;
    }

    if (isAnalyzing && processedQuestions.length === 0) {
      return (
        <div className="flex items-center justify-center h-full text-gray-500">
          <Loader2 className="h-5 w-5 animate-spin mr-2" />
          <span>Analyzing requirements...</span>
        </div>
      );
    }

    if (processedQuestions.length === 0 && isAnalysisComplete) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-gray-500">
          <HelpCircle className="h-8 w-8 mb-2 text-gray-400" />
          <p>No clarifying questions needed</p>
        </div>
      );
    }

    return (
      <div className="space-y-6">
        {pendingQuestions.length > 0 && (
          <div className="space-y-4">
            {pendingQuestions.map((question) => (
              <ClarifyingQuestion 
                key={question.id}
                questionId={question.id}
                question={question.question_text}
                answer={question.answer_text}
                isAnswered={question.is_answered}
                isSubmitting={submittingQuestionId === question.id}
                affectedTickets={question.affected_tickets}
                onAnswer={handleAnswer}
                onDismiss={handleDismiss}
              />
            ))}
          </div>
        )}

        {answeredQuestions.length > 0 && (
          <div>
            <div className="space-y-4">
              {answeredQuestions.map((question) => (
                <ClarifyingQuestion 
                  key={question.id}
                  questionId={question.id}
                  question={question.question_text}
                  answer={question.answer_text}
                  isAnswered={question.is_answered}
                  isSubmitting={submittingQuestionId === question.id}
                  affectedTickets={question.affected_tickets}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card className="h-[300px] border-0 shadow-lg overflow-hidden flex flex-col">
      <div className="p-3 bg-gray-50 border-b border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <HelpCircle className="h-4 w-4 text-blue-500" />
            <h2 className="font-medium text-gray-700">Clarifying Questions</h2>
          </div>
          <span className="text-sm text-gray-500">{pendingQuestions.length} pending</span>
        </div>
      </div>
      <div 
        ref={scrollRef}
        className="flex-1 p-4 space-y-4 overflow-y-auto"
      >
        {renderContent()}
      </div>
    </Card>
  );
};

export default QuestionsPanel;