import React from 'react';
import { Card } from '../ui/card';
import { LayoutList, Download } from 'lucide-react';
import TicketCard from '../TicketCard';
import LoadingPlaceholder from '../LoadingPlaceholder';
import { useToast } from '../../contexts/ToastContext';
import { Button } from '../ui/button';
import { Tooltip } from '../ui/tooltip';

const TicketsPanel = ({ isLoading, tickets }) => {
  const { addToast } = useToast();

  const handleExport = () => {
    addToast('Exporting tickets to JIRA...', 'info');
    setTimeout(() => {
      addToast('Tickets exported successfully!', 'success');
    }, 2000);
  };

  return (
    <div className="h-full flex flex-col">
      <Card className="flex-1 border-0 shadow-lg overflow-hidden flex flex-col">
        <div className="p-4 bg-gray-50 border-b border-gray-100">
          <div className="flex items-center">
            <LayoutList className="h-5 w-5 text-gray-500" />
            <div className="ml-3">
              <h2 className="font-medium text-gray-900">Generated Tickets</h2>
              <p className="text-sm text-gray-500">Generated structure based on PRD analysis</p>
            </div>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto p-6 space-y-4">
          {isLoading ? (
            <LoadingPlaceholder />
          ) : tickets.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-gray-500">
              <LayoutList className="h-8 w-8 mb-2 text-gray-400" />
              <p>No tickets generated yet</p>
            </div>
          ) : (
            tickets.map((ticket) => (
              <TicketCard 
                key={ticket.id || `ticket-${ticket.ticket_id}`} 
                ticket={ticket} 
              />
            ))
          )}
        </div>
        <div className="p-4 border-t border-gray-100 bg-gray-50">
          {/* <Button 
            className="w-full"
            onClick={handleExport}
            disabled={isLoading || tickets.length === 0}
          >
            <Download className="mr-2 h-4 w-4" />
            Export to JIRA
          </Button> */}
            <Tooltip text="Coming Soon">
              <Button 
                className="w-full opacity-50"
                disabled
              >
                <Download className="mr-2 h-4 w-4" />
                Export to JIRA
              </Button>
            </Tooltip>
        </div>
      </Card>
    </div>
  );
};

export default TicketsPanel;