import React from 'react';
import { Link } from 'react-router-dom';

const footerLinks = [
  // { name: 'About', path: '/about' },
  // { name: 'Pricing', path: '/pricing' },
  // { name: 'FAQ', path: '/faq' },
  // { name: 'Privacy', path: '/privacy' },
  // { name: 'Terms', path: '/terms' },
];

const Footer = () => (
  <footer className="bg-white border-t border-gray-200">
    <div className="max-w-[1800px] mx-auto px-6 py-4">
      <div className="flex flex-wrap items-center justify-between">
        <p className="text-sm text-gray-500">© 2024 Blueprint.dev. All rights reserved.</p>
        <div className="flex items-center space-x-6">
          {footerLinks.map((link) => (
            <Link
              key={link.name}
              to={link.path}
              className="text-sm text-gray-600 hover:text-gray-900"
            >
              {link.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;