import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import PRDRequest from './pages/PRDRequest';  
import PRDAnalyzer from './pages/PRDAnalyzer'; 
import About from './pages/About';
import Pricing from './pages/Pricing';
import FAQ from './pages/FAQ';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import { ToastProvider } from './contexts/ToastContext';
import { Analytics } from '@vercel/analytics/react';

const App = () => {
  return (
    <Router>
      <ToastProvider>
        <div className="min-h-screen flex flex-col">
          <Navbar />
          <main className="flex-1">
            <Routes>
              <Route path="/" element={<PRDRequest />} />
              <Route path="/analyze/:requestId" element={<PRDAnalyzer />} />
              <Route path="/about" element={<About />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </main>
          <Footer />
          <Analytics />
        </div>
      </ToastProvider>
    </Router>
  );
};

export default App;