import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/button';
import { Tooltip } from '../ui/tooltip';

const Navbar = () => {
  const location = useLocation();
  
  return (
    <div className="w-full h-16 border-b border-gray-200 bg-white">
      <div className="h-full max-w-[1800px] mx-auto px-6 flex items-center justify-between">
        <Link to="/" className="font-semibold text-xl text-blue-600">Blueprint</Link>
        <div className="flex items-center space-x-8">
          {/* <Link 
            to="/about" 
            className={`${location.pathname === '/about' ? 'text-blue-600' : 'text-gray-600 hover:text-gray-900'}`}
          >
            About
          </Link> */}
          {/* <Link 
            to="/pricing" 
            className={`${location.pathname === '/pricing' ? 'text-blue-600' : 'text-gray-600 hover:text-gray-900'}`}
          >
            Pricing
          </Link> */}
          {/* <Tooltip text="Coming Soon">
            <span className="text-gray-400 cursor-not-allowed">Pricing</span>
          </Tooltip> */}
          {/* <Link 
            to="/faq" 
            className={`${location.pathname === '/faq' ? 'text-blue-600' : 'text-gray-600 hover:text-gray-900'}`}
          >
            FAQ
          </Link> */}
          {/* <Button variant="outline">Sign In</Button> */}
          {/* <Tooltip text="Coming Soon">
            <Button variant="outline" className="opacity-50 cursor-not-allowed" disabled>
              Sign In
            </Button>
          </Tooltip> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;