// src/pages/PRDAnalyzer/components/AnalysisHeader.js
import React from 'react';
import { ArrowLeft, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '../../../components/ui/button';

const AnalysisHeader = ({ isExpanded, onToggleExpand }) => (
  <div className="shrink-0 border-b border-gray-100 bg-white flex items-center justify-between p-2">
    <Button 
      variant="ghost" 
      size="sm" 
      onClick={() => window.location.href = '/'}
      className="text-gray-600 hover:text-gray-900"
    >
      <ArrowLeft className="h-4 w-4 mr-2" />
      New Analysis
    </Button>
    <Button
      variant="ghost"
      size="sm"
      onClick={onToggleExpand}
      className="text-gray-400 hover:text-gray-600"
    >
      {isExpanded ? (
        <>
          <ChevronLeft className="h-4 w-4 mr-1" />
          <span className="text-sm">Collapse</span>
        </>
      ) : (
        <>
          <span className="text-sm">Expand</span>
          <ChevronRight className="h-4 w-4 ml-1" />
        </>
      )}
    </Button>
  </div>
);

export default AnalysisHeader;