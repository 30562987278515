import React from 'react';
import { Loader2 } from 'lucide-react';

const LoadingPlaceholder = () => (
  <div className="flex items-center justify-center h-full">
    <div className="flex flex-col items-center space-y-4">
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-full animate-pulse opacity-20"></div>
        <Loader2 className="h-8 w-8 animate-spin text-blue-600 relative z-10" />
      </div>
      <div className="space-y-1 text-center">
        <p className="text-sm font-medium text-gray-900">Transforming Vision</p>
        <p className="text-sm text-gray-500">Our experts are analyzing your requirements...</p>
      </div>
    </div>
  </div>
);

export default LoadingPlaceholder;