// src/pages/PRDAnalyzer/components/TasksHeader.js
import React from 'react';
import { Download } from 'lucide-react';
import { Button } from '../../../components/ui/button';
import { Tooltip } from '../../../components/ui/tooltip';

const TasksHeader = ({ requestId, ticketCount, onExport, isLoading }) => (
  <div className="shrink-0 border-b border-gray-100 bg-white p-4">
    <div className="flex items-center justify-between">
      <div>
        <h2 className="text-lg font-semibold text-gray-900">Development Tasks</h2>
      </div>
      <div className="flex items-center space-x-4">
        <span className="text-sm text-gray-500">
          {ticketCount} tasks generated
        </span>
        {/* <Button 
          onClick={onExport}
          className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white shadow-sm"
          disabled={isLoading || ticketCount === 0}
        >
          <Download className="h-4 w-4 mr-2" />
          Export Tasks
        </Button> */}

        <Tooltip text="Coming Soon">
          <Button 
            className="w-full opacity-50"
            disabled
          >
            <Download className="mr-2 h-4 w-4" />
            Export Tasks
          </Button>
        </Tooltip>
      </div>
    </div>
  </div>
);

export default TasksHeader;