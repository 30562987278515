import React, { useState, useEffect, useRef } from 'react';
import { Lightbulb, Cpu, Layout, Loader2, AlertCircle } from 'lucide-react';

const TYPING_SPEED = 10; // ms per character

const ExpertMessage = ({ expert, message, isTyping, isError, isLatest }) => {
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [isAnimating, setIsAnimating] = useState(true);
  const messageRef = useRef(message);
  const animationRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Always show full message for non-latest messages
    if (!isLatest) {
      setDisplayedMessage(message);
      setIsAnimating(false);
      return;
    }

    // Handle new message
    if (message !== messageRef.current || isLatest) {
      messageRef.current = message;
      setIsAnimating(true);
      setDisplayedMessage('');
      
      let index = 0;
      const startTime = Date.now();
      
      const animate = () => {
        const elapsed = Date.now() - startTime;
        const targetIndex = Math.floor(elapsed / TYPING_SPEED);
        
        if (targetIndex <= message.length) {
          setDisplayedMessage(message.slice(0, targetIndex));
          if (targetIndex < message.length) {
            animationRef.current = requestAnimationFrame(animate);
          } else {
            // Only keep animating if we're still typing (waiting for next expert)
            if (isTyping) {
              setIsAnimating(true);
            } else {
              setIsAnimating(false);
            }
          }
        }
      };

      animationRef.current = requestAnimationFrame(animate);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [message, isLatest, isTyping]);

  const getExpertIcon = (expertType) => {
    switch (expertType) {
      case 'Vision Expert':
        return Lightbulb;
      case 'Architecture Expert':
        return Cpu;
      default:
        return Layout;
    }
  };

  const getExpertStyles = (expertType) => {
    if (isError) {
      return 'bg-red-50 text-red-600';
    }
    
    switch (expertType) {
      case 'Vision Expert':
        return 'bg-blue-50 text-blue-600';
      case 'Architecture Expert':
        return 'bg-purple-50 text-purple-600';
      default:
        return 'bg-indigo-50 text-indigo-600';
    }
  };

  const Icon = isError ? AlertCircle : getExpertIcon(expert);

  // Only show loading indicators if we're explicitly in a typing state
  const showLoadingIndicators = isTyping && isAnimating;

  return (
    <div className="group flex items-start space-x-3 py-4 transition-all">
      <div className={`p-2 rounded-lg shrink-0 ${getExpertStyles(expert)}`}>
        <Icon className="h-4 w-4" />
      </div>
      <div className="flex-1 space-y-1">
        <div className="flex items-center space-x-2">
          <span className="font-medium text-sm text-gray-900">{expert}</span>
          {showLoadingIndicators && (
            <Loader2 className="h-3 w-3 animate-spin text-blue-500" />
          )}
        </div>
        <div className="relative">
          <p className={`text-sm leading-relaxed whitespace-pre-wrap ${
            isError ? 'text-red-600' : 'text-gray-600'
          }`}>
            {displayedMessage}
            {showLoadingIndicators && (
              <span className="inline-block w-1 h-4 ml-1 bg-blue-500 animate-blink" />
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExpertMessage;