import { useState, useEffect } from 'react';
import { getPRDStatus } from '../../../services/api';

export const useAnalysisData = (requestId, addToast) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPRDStatus(requestId);
        console.log('Fetched PRD Status:', response);
        setData(response);
      } catch (error) {
        console.error("Error loading PRD data:", error);
        addToast('Failed to load PRD analysis', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [requestId, addToast]);

  const updateData = (updates) => {
    setData(prevData => ({
      ...prevData,
      ...updates
    }));
  };

  return { isLoading, data, updateData };
};

export default useAnalysisData;
