import React from 'react';

const Terms = () => (
  <div className="max-w-3xl mx-auto px-6 py-12">
    <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
    <div className="prose prose-lg">
      <p className="text-gray-600 mb-6">
        Last updated: November 12th, 2024
      </p>
      
      <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">1. Acceptance of Terms</h2>
      <p className="text-gray-600 mb-6">
        By accessing or using Blueprint, you agree to be bound by these Terms of Service and all
        applicable laws and regulations.
      </p>

      <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">2. Use License</h2>
      <p className="text-gray-600 mb-6">
        Blueprint grants you a limited, non-exclusive, non-transferable license to use our service
        for your internal business purposes in accordance with these Terms.
      </p>

      <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">3. Service Modifications</h2>
      <p className="text-gray-600 mb-6">
        We reserve the right to modify or discontinue our service at any time, with or without notice.
        We shall not be liable to you or any third party for any modification, suspension, or discontinuance.
      </p>

      <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Contact</h2>
      <p className="text-gray-600">
        Questions about the Terms of Service should be sent to legal@getblueprint.dev
      </p>
    </div>
  </div>
);

export default Terms;