import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from '../../contexts/ToastContext';
import ExpertAnalysisPanel from '../../components/ExpertAnalysisPanel';
import QuestionsPanel from '../../components/QuestionsPanel';
import TicketsPanel from '../../components/TicketsPanel';
import AnalysisHeader from './components/AnalysisHeader';
import TasksHeader from './components/TasksHeader';
import useExpertAnalysis from './hooks/UseExpertAnalysis';
import { useAnalysisData } from './hooks/UseAnalysisData';
import { getAnalysisChain } from './utils/AnalysisHelpers';

const PRDAnalyzer = () => {
  const { requestId } = useParams();
  const { addToast } = useToast();
  const [isExpertPanelExpanded, setIsExpertPanelExpanded] = useState(false);
  
  const { isLoading, data, updateData } = useAnalysisData(requestId, addToast);
  const { processingExpertId, hasExpertError, isAnalysisComplete } = useExpertAnalysis(
    data, 
    requestId, 
    isLoading, 
    addToast,
    updateData
  );

  const handleQuestionsUpdate = (updatedQuestions) => {
    updateData({ questions: updatedQuestions });
  };

  const handleTicketsUpdate = (updatedTickets = [], newTickets = []) => {
    const currentTickets = [...(data?.tickets || [])];
  
    if (Array.isArray(updatedTickets)) {
      updatedTickets.forEach(updatedTicket => {
        const index = currentTickets.findIndex(t => t.id === updatedTicket.id);
        if (index !== -1) {
          currentTickets[index] = updatedTicket;
        }
      });
    }
  
    if (Array.isArray(newTickets) && newTickets.length > 0) {
      currentTickets.push(...newTickets);
    }
  
    updateData({ tickets: currentTickets });
  };

  // Define handleExpertResponse function
  const handleExpertResponse = (expertResponse) => {
    const updatedResponses = [...(data?.expert_responses || []), expertResponse];
    updateData({ expert_responses: updatedResponses });
  };

  const handleExportTasks = () => {
    addToast('Exporting tickets...', 'info');
    // Implement export logic here
  };

  return (
    <div className="flex h-[calc(100vh-4rem)]">
      <div className={`flex flex-col border-r border-gray-100 bg-gray-50 transition-all duration-300 ease-in-out ${
        isExpertPanelExpanded ? 'w-[65%]' : 'w-[40%]'
      }`}>
        <AnalysisHeader 
          isExpanded={isExpertPanelExpanded}
          onToggleExpand={() => setIsExpertPanelExpanded(!isExpertPanelExpanded)}
        />
        
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="flex-1 min-h-[50%] overflow-hidden p-4 border-b border-gray-200">
          <ExpertAnalysisPanel 
            experts={data?.experts || []}
            initialResponses={getAnalysisChain(data)}
            processingExpertId={processingExpertId}
            isAnalysisComplete={isAnalysisComplete}
            hasError={hasExpertError}
          />
          </div>
          
          <div className="flex-1 min-h-[50%] overflow-hidden p-4">
            <QuestionsPanel 
              isLoading={isLoading}
              questions={data?.questions || []}
              isAnalysisComplete={isAnalysisComplete}
              isAnalyzing={processingExpertId !== null}
              onQuestionsUpdate={handleQuestionsUpdate}
              onTicketsUpdate={handleTicketsUpdate} // Pass handleTicketsUpdate
              onExpertResponse={handleExpertResponse} // Pass handleExpertResponse
            />
          </div>
        </div>
      </div>

      <div className={`flex-1 flex flex-col bg-white overflow-hidden transition-all duration-300 ease-in-out ${
        isExpertPanelExpanded ? 'opacity-90' : 'opacity-100'
      }`}>
        <TasksHeader 
          requestId={requestId}
          ticketCount={data?.tickets?.length || 0}
          onExport={handleExportTasks}
          isLoading={isLoading}
        />
        <div className="flex-1 overflow-hidden">
          <div className="h-full p-6">
            <TicketsPanel 
              isLoading={isLoading}
              tickets={data?.tickets || []} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PRDAnalyzer;
