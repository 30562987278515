import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-6">
      <button
        className="flex w-full items-center justify-between text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-medium text-gray-900">{question}</h3>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        )}
      </button>
      {isOpen && (
        <div className="mt-4 text-gray-600 prose-sm">
          {answer}
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: "How does Blueprint analyze product requirements?",
      answer: "Blueprint uses a sophisticated chain-of-thought AI system with a mixture of expert agents. Each agent specializes in different aspects of analysis - from understanding requirements to planning architecture. These agents work together, engaging in detailed analysis and discussion to break down complex product specifications into well-structured tickets. The system uses cutting-edge AI to understand context, identify dependencies, and ensure comprehensive coverage of all requirements."
    },
    {
      question: "How accurate is the ticket generation?",
      answer: "Our AI system achieves high accuracy through its multi-agent approach. Each ticket is analyzed from multiple perspectives - requirements, architecture, and project management. You can review and adjust all generated tickets before finalizing them. The system also asks clarifying questions when it needs more context, ensuring high-quality output."
    },
    {
      question: "What kind of documents work best with Blueprint?",
      answer: "Blueprint works best with detailed product specifications that include clear feature descriptions and requirements. However, our AI system can work with various levels of detail and will ask clarifying questions when needed. We recommend including user stories, technical requirements, and any specific constraints or considerations in your documentation."
    },
    {
      question: "Can I try Blueprint before purchasing?",
      answer: "We offer a 14-day money back guarantee with full access to all available features in our Startup plan. This gives you the opportunity to test our AI analysis system and see how it works with your product requirements."
    },
    {
      question: "Is my data secure?",
      answer: "Yes, we take security seriously. All product documentation is encrypted in transit and at rest. We don't store your documents after processing, and all analysis is performed in secure, isolated environments. Your data is never used to train our AI models."
    },
    {
      question: "Do you offer enterprise features?",
      answer: "Yes, our Company plan includes enterprise features like unlimited team members and dedicated support. We're also developing additional enterprise features including SSO authentication, advanced analytics, and API access. Contact our sales team for more details about our enterprise offerings."
    },
    {
      question: "How do I get started?",
      answer: "Getting started is simple! Paste your product requirements into our interface, and let our AI experts analyze it. You'll see the analysis process in real-time as our expert agents break down your requirements into structured tickets."
    },
    {
      question: "What makes Blueprint different from other tools?",
      answer: "Blueprint stands out through its unique multi-agent AI architecture. Instead of using a single AI model, we employ multiple specialized agents that collaborate and cross-validate each other's work. This approach, combined with our real-time analysis visibility, ensures more thorough and accurate results than traditional requirement processing tools."
    }
  ];

  return (
    <div className="max-w-3xl mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-12">Frequently Asked Questions</h1>
      <div className="space-y-0">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;