import { useState, useEffect, useRef } from 'react';
import { submitExpertAnalysis } from '../../../services/api';

export const useExpertAnalysis = (data, requestId, isLoading, addToast, updateData) => {
  const [processingExpertId, setProcessingExpertId] = useState(null);
  const [hasExpertError, setHasExpertError] = useState(false);

  const processingRef = useRef(false);
  const errorRef = useRef(false);

  const isAnalysisComplete = !data?.experts || 
    data.experts.every(expert => 
      data.expert_responses.some(response => response.expert_id === expert.id && response.status === 'COMPLETED')
    );

  useEffect(() => {
    if (!data?.experts || isLoading || processingRef.current || errorRef.current || isAnalysisComplete) return;

    const processExperts = async () => {
      const nextExpertIndex = data.experts.findIndex(expert => {
        const response = data.expert_responses.find(r => r.expert_id === expert.id);
        return !response || response.status !== 'COMPLETED';
      });

      if (nextExpertIndex === -1) return;

      const expertToProcess = data.experts[nextExpertIndex];
      console.log(`Processing expert: ${expertToProcess.name} (ID: ${expertToProcess.id})`);

      try {
        processingRef.current = true;
        setProcessingExpertId(expertToProcess.id);

        const response = await submitExpertAnalysis(requestId, expertToProcess.id);
        console.log('Response from submitExpertAnalysis:', response);

        if (response.success) {
          // Prepare the expert response
          const expertResponse = {
            expert_id: expertToProcess.id,
            expert: expertToProcess.name,
            response_text: response.response,
            status: 'COMPLETED',
            new_tickets: response.new_tickets || [],
            new_questions: response.new_questions || []
          };

          // Process new questions if they exist
          const currentQuestions = [...(data.questions || [])];
          const newQuestions = response.new_questions || [];
          
          // Add IDs to new questions if they don't have them
          const processedQuestions = newQuestions.map((question, index) => ({
            id: question.id || `temp-${Date.now()}-${index}`,
            question_text: question.question_text || question,
            is_answered: false,
            affected_tickets: question.affected_tickets || [],
            expert_id: expertToProcess.id
          }));

          // Update the data with new questions and expert response
          updateData({
            expert_responses: [...(data.expert_responses || []), expertResponse],
            tickets: [...(data.tickets || []), ...(response.new_tickets || [])],
            questions: [...currentQuestions, ...processedQuestions]
          });

          return expertResponse;
        } else {
          throw new Error('Expert analysis failed');
        }
      } catch (error) {
        console.error(`Error processing expert ${expertToProcess.name}:`, error);
        errorRef.current = true;
        addToast('Expert analysis failed. Please refresh the page to try again.', 'error');
        
        const errorResponse = {
          expert_id: expertToProcess.id,
          expert: expertToProcess.name,
          response_text: 'Analysis failed. Please refresh the page to try again.',
          status: 'ERROR'
        };
        
        updateData({
          expert_responses: [...(data.expert_responses || []), errorResponse]
        });
      } finally {
        processingRef.current = false;
        setProcessingExpertId(null);
      }
    };

    processExperts();
  }, [data, requestId, addToast, isLoading, updateData, isAnalysisComplete]);

  return {
    processingExpertId,
    hasExpertError: errorRef.current,
    isAnalysisComplete
  };
};

export default useExpertAnalysis;