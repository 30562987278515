import React, { useState, useEffect } from 'react';
import { HelpCircle, X, Send, Loader2, Edit2 } from 'lucide-react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';

const ClarifyingQuestion = ({ 
  question, 
  questionId, 
  answer, 
  onAnswer, 
  onDismiss, 
  isAnswered, 
  isSubmitting,
  affectedTickets 
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  // Handle both string and object question formats
  const questionText = typeof question === 'string' ? question : question?.question_text;
  const questionIsAnswered = isAnswered || 
    (typeof question === 'object' && (question?.is_answered || Boolean(question?.answer_text)));
  const questionAnswer = answer || (typeof question === 'object' && question?.answer_text);

  const handleSubmit = () => {
    if (inputValue.trim()) {
      onAnswer(questionId, inputValue, isEditing);
    }
  };

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss(questionId);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const startEditing = () => {
    setInputValue(questionAnswer);
    setIsEditing(true);
  };

  // Reset editing state when submission completes
  useEffect(() => {
    if (!isSubmitting && isEditing) {
      setIsEditing(false);
    }
  }, [isSubmitting]);

  if (!questionText) {
    return null;
  }

  return (
    <div className="flex items-start space-x-3 p-3 bg-gray-50 rounded-lg">
      <HelpCircle className="h-5 w-5 text-blue-500 mt-1 shrink-0" />
      <div className="flex-1 space-y-3">
        <p className="text-sm text-gray-700">{questionText}</p>
        {(questionIsAnswered && !isEditing) ? (
          <div className="text-sm bg-white p-2 rounded border border-gray-200">
            <div className="flex justify-between items-start">
              <div>
                <p className="font-medium text-gray-600">Answer:</p>
                <p className="text-gray-600">{questionAnswer}</p>
              </div>
              {isSubmitting ? (
                <Loader2 className="h-4 w-4 animate-spin ml-2 text-gray-500" />
              ) : (
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={startEditing}
                  className="ml-2 text-gray-500 hover:text-gray-700"
                >
                  <Edit2 className="h-4 w-4" />
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="flex space-x-2">
            <Input 
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={isEditing ? "Edit your answer..." : "Your answer..."}
              className="flex-1 text-sm"
              onKeyPress={handleKeyPress}
              disabled={isSubmitting}
            />
            {!isEditing && onDismiss && (
              <Button 
                size="sm" 
                variant="outline" 
                className="shrink-0"
                onClick={handleDismiss}
                disabled={isSubmitting}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
            <Button 
              size="sm" 
              className="shrink-0" 
              onClick={handleSubmit}
              disabled={!inputValue.trim() || isSubmitting}
            >
              {isSubmitting ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Send className="h-4 w-4" />
              )}
            </Button>
          </div>
        )}
        {affectedTickets && affectedTickets.length > 0 && (
          <div className="text-xs text-gray-500">
            Affects tickets: {affectedTickets.join(', ')}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClarifyingQuestion;