import React from 'react';

const About = () => (
  <div className="max-w-4xl mx-auto px-6 py-12">
    <h1 className="text-4xl font-bold text-gray-900 mb-6">About Blueprint</h1>
    <div className="prose prose-lg">
      <p className="text-gray-600 mb-6">
        Blueprint is an AI-powered tool that transforms Product Requirement Documents (PRDs) into 
        actionable tasks and tickets. Our mission is to streamline the software development process 
        by bridging the gap between product vision and engineering execution.
      </p>
      
      <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Our Story</h2>
      <p className="text-gray-600 mb-6">
        Founded by a team of product managers and engineers who experienced firsthand the challenges 
        of translating product requirements into development tasks, Blueprint was born from the need 
        to make this process more efficient and accurate.
      </p>
      
      <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Our Technology</h2>
      <p className="text-gray-600 mb-6">
        Using advanced natural language processing and machine learning, Blueprint analyzes PRDs to 
        extract key requirements, identify dependencies, and generate properly structured development 
        tickets that integrate seamlessly with your existing project management tools.
      </p>
      
      <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Our Vision</h2>
      <p className="text-gray-600">
        We envision a future where the transition from product planning to development is seamless 
        and efficient, allowing teams to focus on what they do best - building great products.
      </p>
    </div>
  </div>
);

export default About;