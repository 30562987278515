import React from 'react';

const Privacy = () => (
  <div className="max-w-3xl mx-auto px-6 py-12">
    <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
    <div className="prose prose-lg">
      <p className="text-gray-600 mb-6">
        Last updated: March 14, 2024
      </p>
      
      <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Overview</h2>
      <p className="text-gray-600 mb-6">
        Blueprint is committed to protecting your privacy. This Privacy Policy explains how we collect,
        use, and safeguard your information when you use our service.
      </p>

      <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Information We Collect</h2>
      <p className="text-gray-600 mb-6">
        We collect information that you provide directly to us, including PRD content, account information,
        and usage data. This information is used solely for providing and improving our services.
      </p>

      <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Data Security</h2>
      <p className="text-gray-600 mb-6">
        We implement robust security measures to protect your data. All information is encrypted in transit
        and at rest. We do not store PRD content after processing is complete.
      </p>

      <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Contact Us</h2>
      <p className="text-gray-600">
        If you have any questions about this Privacy Policy, please contact us at privacy@getblueprint.dev
      </p>
    </div>
  </div>
);

export default Privacy;