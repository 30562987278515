import React, { useState, useEffect } from 'react';
import { Card } from '../ui/card';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import { MessagesSquare, Upload, FileText, Sparkles, AlertCircle, Loader2, Info } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/alert';
import { Tooltip } from '../ui/tooltip';

const CHAR_LIMIT = 6000;

const StartScreen = ({ prdText, setPrdText, onStart, isSubmitting }) => {
  const [dragActive, setDragActive] = useState(false);
  const [showTips, setShowTips] = useState(true);
  const [showLimitAlert, setShowLimitAlert] = useState(false);

  useEffect(() => {
    // Show limit alert for 3 seconds when text is trimmed
    if (prdText.length > CHAR_LIMIT) {
      setShowLimitAlert(true);
      const timer = setTimeout(() => setShowLimitAlert(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [prdText]);

  const handleTextChange = (e) => {
    const text = e.target.value;
    if (text.length > CHAR_LIMIT) {
      setPrdText(text.slice(0, CHAR_LIMIT));
      setShowLimitAlert(true);
    } else {
      setPrdText(text);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        if (text.length > CHAR_LIMIT) {
          setPrdText(text.slice(0, CHAR_LIMIT));
          setShowLimitAlert(true);
        } else {
          setPrdText(text);
        }
      };
      reader.readAsText(file);
    }
  };

  const getCharCountColor = () => {
    const ratio = prdText.length / CHAR_LIMIT;
    if (ratio >= 0.9) return 'text-red-500';
    if (ratio >= 0.7) return 'text-yellow-500';
    return 'text-gray-500';
  };

  return (
    <div className="max-w-4xl mx-auto space-y-8 p-6">
      <div className="text-center space-y-6">
        <div className="inline-flex items-center justify-center space-x-2 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-full px-4 py-1.5 shadow-sm border border-blue-100">
          <Sparkles className="h-4 w-4 text-blue-500" />
          <span className="text-sm font-medium bg-gradient-to-r from-blue-600 to-indigo-600 text-transparent bg-clip-text">Powered by AI</span>
        </div>
        
        <div className="space-y-4">
          <h1 className="text-5xl font-bold text-gray-900">Vision to Action</h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Transform your PRD into perfectly structured development tasks in minutes
          </p>
        </div>
      </div>

      {showTips && (
        <Alert className="bg-gradient-to-r from-blue-50 to-indigo-50 border-blue-100">
          <AlertCircle className="h-4 w-4 text-blue-500" />
          <AlertDescription className="text-blue-700">
            <strong>Quick Tip:</strong> Include your product requirement document, key features, and desired outcomes for the most comprehensive analysis.
            <button 
              onClick={() => setShowTips(false)} 
              className="ml-2 text-blue-600 hover:text-blue-800 underline"
            >
              Dismiss
            </button>
          </AlertDescription>
        </Alert>
      )}

      {showLimitAlert && (
        <Alert className="bg-yellow-50 border-yellow-100">
          <AlertCircle className="h-4 w-4 text-yellow-500" />
          <AlertDescription className="text-yellow-700">
            Text has been trimmed to the demo limit of 6,000 characters.
          </AlertDescription>
        </Alert>
      )}

      <Card className="border-2 border-dashed border-gray-200 hover:border-blue-200 transition-colors backdrop-blur-sm">
        <div 
          className="p-8"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <FileText className="h-5 w-5 text-blue-500" />
                <h2 className="font-medium text-gray-900">Your Vision</h2>
              </div>
              <div className="flex items-center space-x-2">
                <div className="flex items-center">
                  <span className={`text-sm ${getCharCountColor()}`}>
                    {prdText.length}/{CHAR_LIMIT}
                  </span>
                  <Tooltip text="6,000 character limit for demo">
                    <Info className="h-4 w-4 ml-1 text-gray-400 cursor-help" />
                  </Tooltip>
                </div>
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => setPrdText('')}
                  disabled={!prdText || isSubmitting}
                >
                  Clear
                </Button>
              </div>
            </div>

            <Textarea 
              value={prdText}
              onChange={handleTextChange}
              placeholder={dragActive ? 
                "Drop your document here..." : 
                "Describe your product requirement document and features..."
              }
              className={`min-h-[300px] text-base transition-colors ${
                dragActive ? 'bg-blue-50' : 'bg-white'
              } ${prdText.length >= CHAR_LIMIT ? 'border-yellow-300' : ''}`}
              disabled={isSubmitting}
            />

            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Upload className="h-4 w-4 text-gray-400" />
                <span className="text-sm text-gray-500">
                  Paste text or drop a document
                </span>
              </div>
              <Button 
                onClick={onStart}
                className="h-12 px-6 bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-white shadow-md"
                disabled={!prdText.trim() || isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                    Processing...
                  </>
                ) : (
                  <>
                    <MessagesSquare className="mr-2 h-5 w-5" />
                    Transform Vision
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default StartScreen;