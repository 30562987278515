import React, { useRef, useEffect, useState } from 'react';
import { Card } from '../ui/card';
import { Lightbulb, AlertCircle } from 'lucide-react';
import ExpertMessage from '../ExpertMessage';

const ExpertAnalysisPanel = ({ 
  experts,
  initialResponses = [],
  processingExpertId,
  isAnalysisComplete,
  hasError
}) => {
  const scrollRef = useRef(null);
  const [analysisChain, setAnalysisChain] = useState(initialResponses);

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }, 100);
    }
  }, [analysisChain]);

  // Update analysis chain when initialResponses changes
  useEffect(() => {
    if (Array.isArray(initialResponses)) {
      const uniqueResponses = initialResponses.reduce((acc, response) => {
        const exists = acc.some(
          (item) => item.expert === response.expert && item.message === response.message
        );
        if (!exists) acc.push({
          expert: response.expert || "Unknown Expert",
          message: response.message || response.response_text,
          isComplete: true,
          isError: false
        });
        return acc;
      }, []);
      setAnalysisChain(uniqueResponses);
    }
  }, [initialResponses]);

  return (
    <Card className="flex flex-col h-full border-0 shadow-lg overflow-hidden bg-gradient-to-b from-white to-gray-50">
      <div className="p-3 bg-gray-50 border-b border-gray-100">
        <div className="flex items-center space-x-2">
          <Lightbulb className="h-5 w-5 text-blue-600 shrink-0" />
          <h2 className="font-medium text-gray-700">Expert Analysis</h2>
        </div>
      </div>
      <div 
        ref={scrollRef}
        className="flex-1 p-4 space-y-4 overflow-y-auto"
      >
        {analysisChain.map((message, index) => (
          <ExpertMessage 
            key={`${message.expert}-${index}`}
            expert={message.expert}
            message={message.message}
            isTyping={false}
            isError={message.isError}
            isLatest={index === analysisChain.length - 1}
          />
        ))}
        
        {processingExpertId && !isAnalysisComplete && !hasError && (
          <ExpertMessage 
            expert={experts.find(e => e.id === processingExpertId)?.name || "Expert"}
            message="Analyzing requirements..."
            isTyping={true}
            isLatest={true}
          />
        )}

        {hasError && (
          <ExpertMessage 
            expert="System"
            message="Analysis failed. Please refresh the page to try again."
            isError={true}
            isLatest={true}
          />
        )}
      </div>
    </Card>
  );
};

export default ExpertAnalysisPanel;