// src/pages/PRDAnalyzer/utils/analysisHelpers.js
export const getAnalysisChain = (data) => {
    if (!data || !data.expert_responses) return [];
    
    return data.expert_responses
      .filter(response => response.status === 'COMPLETED' && response.response_text)
      .map(response => {
        if (response.expert_id === 'clarification' || response.expert === 'Clarification Expert') {
          return {
            expert: 'Clarification Expert',
            message: response.response_text,
            isComplete: true,
            created_at: response.created_at
          };
        }
        
        return {
          expert: response.expert || data.experts?.find(e => e.id === response.expert_id)?.name || 'Unknown Expert',
          message: response.response_text,
          isComplete: true,
          created_at: response.created_at
        };
      })
      .sort((a, b) => {
        const dateA = a.created_at ? new Date(a.created_at) : new Date(0);
        const dateB = b.created_at ? new Date(b.created_at) : new Date(0);
        return dateA - dateB;
      });
  };