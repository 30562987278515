import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StartScreen from '../../components/StartScreen';
import { useToast } from '../../contexts/ToastContext';
import { submitPRD } from '../../services/api';

const PRDRequest = () => {
  const [prdText, setPrdText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();

  const handleStart = async () => {
    if (!prdText.trim()) {
      addToast('Please enter your product vision', 'error');
      return;
    }

    setIsSubmitting(true);
    try {
      const result = await submitPRD(prdText);
      if (result.success) {
        // Navigate to the request page
        navigate(`/analyze/${result.requestUuid}`);
      } else {
        addToast('Failed to process PRD', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      addToast('An error occurred while processing your PRD', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <StartScreen 
      prdText={prdText} 
      setPrdText={setPrdText} 
      onStart={handleStart}
      isSubmitting={isSubmitting}
    />
  );
};

export default PRDRequest;