import React, { useState } from 'react';
import { ArrowRight, CheckCircle2, ChevronDown, ChevronUp, Tag, Clock, MessageCircle } from 'lucide-react';
import { Button } from '../ui/button';
import { Tooltip } from '../ui/tooltip';

const TicketCard = ({ ticket }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getPriorityColor = (priority) => {
    const colors = {
      HIGH: 'bg-red-50 text-red-700',
      MEDIUM: 'bg-yellow-50 text-yellow-700',
      LOW: 'bg-green-50 text-green-700'
    };
    return colors[priority] || 'bg-gray-50 text-gray-700';
  };

  const getTypeStyles = (type) => {
    const styles = {
      EPIC: 'bg-purple-50 text-purple-700 border-purple-200',
      STORY: 'bg-blue-50 text-blue-700 border-blue-200',
      TASK: 'bg-gray-50 text-gray-700 border-gray-200'
    };
    return styles[type] || styles.TASK;
  };

  return (
    <div className="group bg-white rounded-lg border border-gray-200 hover:border-blue-200 transition-all duration-200">
      <div className="p-4">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center space-x-3">
            <span className={`px-2 py-1 text-xs font-medium rounded-full border ${getTypeStyles(ticket.type)}`}>
              {ticket.type}
            </span>
            <span className={`px-2 py-1 text-xs font-medium rounded-full ${getPriorityColor(ticket.priority)}`}>
              {ticket.priority} Priority
            </span>
            {ticket.is_provisional && (
              <span className="px-2 py-1 text-xs font-medium rounded-full bg-blue-50 text-blue-700 border border-blue-200">
                Provisional
              </span>
            )}
          </div>
          
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsExpanded(!isExpanded)}
            className="p-1 h-auto hover:bg-gray-100"
          >
            {isExpanded ? (
              <ChevronUp className="h-4 w-4 text-gray-400" />
            ) : (
              <ChevronDown className="h-4 w-4 text-gray-400" />
            )}
          </Button>
        </div>

        <h3 className="font-medium text-gray-900 mb-2">{ticket.title}</h3>
        
        {isExpanded && (
          <>
            <p className="text-sm text-gray-600 mb-4">{ticket.description}</p>
            
            <div className="space-y-4">
              {ticket.acceptance_criteria?.length > 0 && (
                <div className="border-t border-gray-100 pt-4">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">Acceptance Criteria</h4>
                  <ul className="space-y-2">
                    {ticket.acceptance_criteria.map((criteria, index) => (
                      <li key={index} className="flex items-start text-sm text-gray-600">
                        <CheckCircle2 className="h-4 w-4 text-green-500 mr-2 mt-0.5 shrink-0" />
                        {criteria}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              
              {ticket.assumptions && (
                <div className="border-t border-gray-100 pt-4">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">Assumptions</h4>
                  <p className="text-sm text-gray-600">{ticket.assumptions}</p>
                </div>
              )}

              <div className="flex items-center justify-end border-t border-gray-100 pt-4">
                <Tooltip text="Coming Soon">
                  <Button size="sm" disabled variant="outline">
                    Edit Ticket
                  </Button>
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TicketCard;