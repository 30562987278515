import React from 'react';
import { Check, Clock } from 'lucide-react';
import { Button } from '../../components/ui/button';

const PricingTier = ({ name, price, description, features, highlighted = false }) => (
  <div className={`p-8 rounded-xl ${highlighted ? 'bg-blue-50 border-blue-200' : 'bg-white border-gray-200'} border`}>
    <h3 className="text-xl font-semibold text-gray-900">{name}</h3>
    <div className="mt-4 flex items-baseline">
      <span className="text-4xl font-bold text-gray-900">${price}</span>
      <span className="ml-1 text-gray-500">/month</span>
    </div>
    <p className="mt-2 text-gray-600">{description}</p>
    <ul className="mt-6 space-y-4">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start">
          {feature.comingSoon ? (
            <Clock className="h-5 w-5 text-blue-500 shrink-0" />
          ) : (
            <Check className="h-5 w-5 text-green-500 shrink-0" />
          )}
          <span className="ml-3 text-gray-600">
            {feature.text}
            {feature.comingSoon && (
              <span className="ml-2 text-sm text-blue-600">(Coming Soon)</span>
            )}
          </span>
        </li>
      ))}
    </ul>
    <Button 
      className={`w-full mt-8 ${highlighted ? '' : 'bg-white text-blue-600 border-blue-600 hover:bg-blue-50'}`}
      variant={highlighted ? 'default' : 'outline'}
    >
      Get started
    </Button>
  </div>
);

const Pricing = () => {
  const tiers = [
    {
      name: 'Startup',
      price: '20',
      description: 'Perfect for startups and solo founders',
      features: [
        { text: 'Unlimited PRD processing' },
        { text: 'AI-powered analysis system' },
        { text: 'Basic ticket generation' },
        { text: 'Email support' },
        { text: 'Ticket templates', comingSoon: true }
      ]
    },
    {
      name: 'Team',
      price: '79',
      description: 'Best for growing teams',
      features: [
        { text: 'Up to 5 team members' },
        { text: 'Everything in Startup plan' },
        { text: 'Priority support' },
        { text: 'Usage analytics', comingSoon: true },
        { text: 'Team collaboration features', comingSoon: true },
      ],
      highlighted: true
    },
    {
      name: 'Company',
      price: '199',
      description: 'For organizations with advanced needs',
      features: [
        { text: 'Unlimited team members' },
        { text: 'Everything in Team plan' },
        { text: 'Advanced ticket templates', comingSoon: true },
        { text: 'Advanced analytics', comingSoon: true },
        { text: 'SSO authentication', comingSoon: true },
        { text: 'API access', comingSoon: true }
      ]
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-6 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900">Choose your plan</h1>
        <p className="mt-4 text-xl text-gray-600">Transform your product requirements into actionable tickets</p>
      </div>
      <div className="grid md:grid-cols-3 gap-8">
        {tiers.map((tier) => (
          <PricingTier key={tier.name} {...tier} />
        ))}
      </div>
      <div className="mt-12 text-center">
        <p className="text-sm text-gray-500">
          Need a custom plan? <a href="mailto:sales@getblueprint.dev" className="text-blue-600 hover:text-blue-800">Contact our sales team</a>
        </p>
      </div>
    </div>
  );
};

export default Pricing;