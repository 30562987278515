import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

export const submitPRD = async (prdText) => {
  const requestUuid = uuidv4();
  
  try {
    const response = await axios.post(`${API_BASE_URL}/api/prd/analyze/`, {
      request_uuid: requestUuid,
      prd_text: prdText
    });
    
    return {
      success: true,
      data: response.data,
      requestUuid
    };
  } catch (error) {
    console.error('Error submitting PRD:', error);
    throw error;
  }
};

export const getPRDStatus = async (requestUuid) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/prd/analyze/`, {
      params: {
        request_uuid: requestUuid
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching PRD status:', error);
    throw error;
  }
};

export const submitExpertAnalysis = async (requestUuid, expertId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/prd/moe/`, {
      request_uuid: requestUuid,
      expert_id: expertId
    });
    console.log('submitExpertAnalysis response:', response.data); // Log response for debugging
    return response.data;
  } catch (error) {
    console.error('Error in submitExpertAnalysis:', error); // Log error
    throw error;
  }
};

export const submitQuestionAnswer = async (questionId, answer, isDismissed = false) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/prd/answer-question/`, {
      question_id: questionId,
      answer: answer,
      dismissed: isDismissed
    });
    
    return response.data;
  } catch (error) {
    console.error('Error submitting question answer:', error);
    throw error;
  }
};
